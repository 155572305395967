import { actionTypes } from './actions';

const initialState = {
  loading: false,
  resources: [],
  events: [],
  locations: []
};

const ScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCHEDULE_UPDATE: {
      const { payload = {} } = action;
      return {
        ...state,
        ...payload
      };
    }
    case actionTypes.SCHEDULE_RESETSTATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ScheduleReducer;
