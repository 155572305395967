import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { AVAILABLE_TYPES, DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import { useMakeUrlProjectId } from 'services/projectUtils';
import { concatGeoFullName, getContrastYIQ, toBills } from 'services/utils';
import { isNumeric } from 'services/validation';

import Field, { LabelField } from 'components/Field';

// const cellW = 40;
const eventH = 20;
const eventBlockH = eventH + 4;
const sidebirCell = 150;
const UNITS = {
  minute: 30,
  day: 0
};

const borderMainColor = '#d2d2d2';
const borderSecondColor = '#d2d2d2';

const DAY_START = 8;
const DAY_END = 22;

const headerDayFormat = 'HH';
const headerWeekFormat = 'ddd (DD MMM)';

const hourMinWidth = 40;
const dayMinWidth = 100;

const headerH = 35;

const styles = {
  wrap: {},
  table: {
    width: '100%',
    overflowX: 'auto',
    borderBottom: `1px solid ${borderMainColor}`,
    position: 'relative'
    // paddingTop: headerH,
  },
  row: {
    whiteSpace: 'nowrap',
    position: 'relative',
    borderTop: `1px solid ${borderMainColor}`
  },
  headerRow: {
    height: headerH
    // position: 'absolute',
    // top: 0,
    // zIndex: 30,
    // background: '#fff',
    // borderBottom: `1px solid ${borderMainColor}`,
  },
  headerCell: {
    height: '100%',
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sidebirCell: {
    width: sidebirCell,
    padding: 5,
    textAlign: 'right',
    whiteSpace: 'normal',
    borderRight: `1px solid ${borderMainColor}`,
    borderLeft: `1px solid ${borderMainColor}`
  },
  th: {
    borderRight: `1px solid ${borderMainColor}`,
    whiteSpace: 'normal'
  },
  td: {
    // width: cellW,
    textAlign: 'center',

    display: 'inline-block',
    minHeight: 30,
    verticalAlign: 'top'
  },
  cellGrid: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    borderRight: `1px solid ${borderMainColor}`,
    zIndex: 0
  },
  cellGridDelimiter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 1,
    background: borderSecondColor
  },
  eventsBlock: {
    height: eventBlockH,
    position: 'absolute',
    left: sidebirCell,
    right: 0,
    overflow: 'hidden',
    zIndex: 10
  },
  event: {
    height: eventH,
    position: 'absolute',
    outline: '1px solid #d2d2d2',
    top: 2,
    backgroundColor: '#eee',
    overflow: 'hidden',
    zIndex: 11,
    padding: 2,
    fontSize: 12
  },
  notRegionEvent: {
    borderTop: '1px solid #000'
  },
  notRegionBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 0,
    height: 0,
    borderTop: '15px solid #0f0',
    borderLeft: '15px solid transparent'
  }
};

class Scheduler extends React.Component {
  render() {
    const {
      events = [],
      resources = [],
      viewType,
      currentDate,
      eventItemClick,
      showTurnover
    } = this.props;
    return (
      <Table
        style={styles.table}
        events={events}
        currentDate={currentDate}
        viewType={viewType}>
        {({ cellW, cols, start, end, cellUnit }) => {
          const newEven = prepareEvents(
            events,
            resources,
            currentDate,
            cellW,
            viewType,
            start,
            end,
            cellUnit
          );
          if (!cols.length || !cellW) return;
          return (
            <>
              <HeaderRow style={{ width: sidebirCell + cols.length * cellW }}>
                <ResourceCell
                  title="Дезинфекторы"
                  style={{ ...styles.headerCell }}
                />
                {cols.map((col, i) => (
                  <Cell
                    key={i}
                    style={{
                      ...styles.th,
                      width: cellW,
                      ...styles.headerCell
                    }}>
                    {col.date.format(
                      viewType === 'week' ? headerWeekFormat : headerDayFormat
                    )}
                  </Cell>
                ))}
              </HeaderRow>
              <div style={{ position: 'relative' }}>
                {cols.map((col, i) => (
                  <CellGrid
                    key={i}
                    cellW={cellW}
                    cellUnit={cellUnit}
                    index={i}
                    viewType={viewType}
                    col={col}
                  />
                ))}
                {resources.map((res) => {
                  const events = newEven[res.id];
                  const hasEvents = Array.isArray(events) && events.length;
                  let minHeight = 0;
                  if (hasEvents)
                    minHeight = eventBlockH * events.length + events.length;
                  return (
                    <Row
                      key={res.id}
                      resource={res}
                      events={newEven[res.id]}
                      style={{ width: sidebirCell + cols.length * cellW }}>
                      <ResourceCellExecutor
                        showTurnover={showTurnover}
                        title={res.title}
                        resource={res}
                        style={{ minHeight: minHeight }}
                      />
                      {hasEvents &&
                        events.map((line, i) => {
                          return (
                            <EventBlock
                              key={i}
                              style={{ top: eventBlockH * i + 1 }}>
                              {line && line.length
                                ? line.map((it) => {
                                    return (
                                      <Event
                                        key={it.event.id}
                                        eventItem={it}
                                        resource={res}
                                        eventItemClick={eventItemClick}
                                      />
                                    );
                                  })
                                : null}
                            </EventBlock>
                          );
                        })}
                    </Row>
                  );
                })}
              </div>
            </>
          );
        }}
      </Table>
    );
  }
}

export default Scheduler;

class HeaderRow extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.refInnerRow = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('scroll', this.documentScroll);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.documentScroll);
  }

  documentScroll = (e) => {
    const { current: el } = this.ref;
    const { current: targetEl } = this.refInnerRow;
    const box = el.getBoundingClientRect();
    // toolbar heigth 64
    const topOfWindow = box.top - 70;
    const elTop = topOfWindow < 0 ? Math.abs(topOfWindow) : 0;
    targetEl.style.top = elTop + 'px';
  };
  render() {
    const { children, style = {} } = this.props;
    return (
      <div
        ref={this.ref}
        style={{
          ...style,
          height: headerH,
          position: 'relative',
          zIndex: 30
        }}>
        <div
          ref={this.refInnerRow}
          style={{
            whiteSpace: 'nowrap',
            position: 'absolute',
            borderTop: `1px solid ${borderMainColor}`,
            borderBottom: `1px solid ${borderMainColor}`,
            left: 0,
            right: 0,
            height: headerH,
            top: 0,
            background: '#fff',
            display: 'flex',
            flexWrap: 'nowrap'
          }}>
          {children}
        </div>
      </div>
    );
  }
}

class TableClass extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    const { currentDate, viewType } = props;
    let start = currentDate.clone().startOf(viewType);
    let end = currentDate.clone().endOf(viewType);

    this.state = {
      cellW: 40,
      cols: [],
      start,
      end,
      cellUnit: 0,
      currentDate
    };
  }
  componentDidMount() {
    // this.calc();
  }

  componentDidUpdate(prev) {
    if (
      prev.currentDate !== this.props.currentDate ||
      prev.viewType !== this.props.viewType ||
      prev.events !== this.props.events
    ) {
      this.calc();
    }
  }

  calc = () => {
    const { currentDate, viewType = 'day', events = [] } = this.props;

    let lessView = 'h';
    switch (viewType) {
      case 'week': {
        lessView = 'd';
        break;
      }
      default:
        lessView = 'h';
    }

    let start = null;
    let end = null;

    if (viewType === 'week') {
      start = currentDate.clone().startOf('day');
      end = start.clone().add(6, 'd').endOf('day');
    } else {
      start = currentDate.clone().startOf('day');
      end = currentDate.clone().endOf('day').set('hour', DAY_END);
    }

    let eventStart = start.clone();
    let eventEnd = end.clone();

    // if (viewType === 'day' && dayStart) {
    eventStart.set('hour', DAY_START);
    // const startH = eventStart.clone();
    // }

    // if (viewType === 'day' && dayEnd) {
    eventEnd.set('hour', DAY_END);
    // }
    events.forEach((it) => {
      if (it.eventType !== 'order') return;
      if (it.start.isSame(eventStart, 'day') && it.start.isBefore(eventStart)) {
        eventStart = it.start.clone();
      }
      if (it.end.isAfter(eventEnd)) {
        eventEnd = it.end.clone();
      }
    });

    eventStart.startOf('hour');
    eventEnd.endOf('hour');

    if (start.isAfter(eventStart)) eventStart = start.clone();
    if (end.isAfter(eventEnd)) eventEnd = end.clone();

    const cols = [];
    const stCount = eventStart.clone();
    while (stCount.isBefore(eventEnd)) {
      cols.push({ date: stCount.clone() });
      stCount.add(1, lessView);
    }

    let minW = viewType === 'week' ? dayMinWidth : hourMinWidth;

    const width = this.ref.current.clientWidth;
    let w = Math.floor((width - sidebirCell) / (cols.length || 1));
    if (w < minW) w = minW;

    const {
      cellW,
      cols: stateCols,
      start: stateStart,
      end: stateEnd
    } = this.state;

    const cellUnit = getCellUnit(w, viewType);

    if (
      (w && w !== cellW) ||
      cols.length !== stateCols.length ||
      !eventStart.isSame(stateStart) ||
      !eventEnd.isSame(stateEnd)
    ) {
      this.setState({
        cellW: w,
        cols,
        start: eventStart,
        end: eventEnd,
        cellUnit
      });
    }
  };
  render() {
    const { children, style = {} } = this.props;
    const { cellW, cols, start, end, cellUnit } = this.state;
    return (
      <div ref={this.ref} style={{ ...style, ...styles.table }}>
        {cellW
          ? children({
              cellW,
              cols,
              start,
              end,
              cellUnit
            })
          : null}
      </div>
    );
  }
}

const Table = TableClass;

const useEventStyles = makeStyles((theme) => ({
  popoverRoot: {
    zIndex: 1250
  },
  popoverPaper: {
    padding: theme.spacing(1)
  },
  title: {
    fontSize: 12
  },
  titleBold: {
    fontWeight: 'bold'
  }
}));

const EventComponent = (props) => {
  const makeUrlProjectId = useMakeUrlProjectId();

  const { eventItem = {}, resource = {}, eventItemClick = () => {} } = props;

  const { event = {}, left, w } = eventItem;

  const { order, title, bgColor, showPopover, eventType } = event;

  const classes = useEventStyles();

  const timer = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHover = (event) => {
    const anchor = anchorEl ? null : event.currentTarget;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setAnchorEl(anchor);
    }, 500);
  };

  const handleHoverOut = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? event.id : undefined;

  const addit = {};
  if (showPopover) {
    addit.onMouseEnter = handleHover;
    addit.onMouseLeave = handleHoverOut;
  }

  const isOrder = eventType === 'order';
  const isAvailable = eventType === 'available';
  const isNotAvailable = eventType === 'notAvailable';

  let hasAgreementNumber = false;
  if (isOrder && order.agreement_number) {
    hasAgreementNumber = true;
  }

  let isActive = true;
  if (isOrder && order.is_active === 'no') {
    isActive = false;
  }

  let contrastColor = '#000';
  if (isOrder) {
    let color = getContrastYIQ(bgColor);
    contrastColor = color === 'white' ? '#fff' : '#000';
    if (!isActive) contrastColor = '#000';
  }

  const isNotRegionOrder =
    isOrder && order.locality && order.locality.region_center === 'no';

  return (
    <div
      onClick={() => eventItemClick(resource, event)}
      title={showPopover ? '' : title}
      style={{
        ...styles.event,
        background: isActive
          ? bgColor
          : 'repeating-linear-gradient(45deg, white, white 7px, #bfbfbf 0, #bfbfbf 14px)',
        // left: left + 4,
        // width: w - 8,
        border: isNotRegionOrder ? '1px solid #0f0' : 'none',
        left: left,
        width: w - 3,
        // right: right,
        cursor:
          isOrder || isAvailable || isNotAvailable ? 'pointer' : 'default',
        display: 'flex'
      }}
      {...addit}>
      {hasAgreementNumber && (
        <div
          style={{
            fontSize: 12,
            border: `1px solid ${contrastColor}`,
            width: 15,
            height: 15,
            borderRadius: '50%',
            marginRight: 3,
            flexShrink: 0
          }}>
          <CheckIcon style={{ fontSize: 'inherit', color: contrastColor }} />
        </div>
      )}
      <div
        style={{ color: contrastColor }}
        className={clsx(classes.title, {
          [classes.titleBold]: isOrder
        })}>
        {!!isNotRegionOrder && <div style={{ ...styles.notRegionBox }}></div>}
        {title}
      </div>
      {showPopover && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.popoverRoot}>
          <Paper classes={{ root: classes.popoverPaper }}>
            {!!isOrder &&
              open &&
              renderPopoverOrder(resource, event, {
                orderLink: makeUrlProjectId(`/orders/show/${order.id}`)
              })}
            {!!isNotAvailable &&
              open &&
              renderPopoverNotAvailable(resource, event)}
          </Paper>
        </Popper>
      )}
    </div>
  );
};

const Event = React.memo(EventComponent);

const CellGridComponent = (props) => {
  const { cellUnit, cellW = 0, index = 0, viewType, col } = props;
  let units = 0;
  if (cellUnit) {
    units = Math.round(cellW / cellUnit);
  }
  const grids = units ? Array(units).fill(1) : [];
  let bg = 'transparent';
  if (viewType === 'week') {
    const day = col.date.isoWeekday();
    if (day === 6 || day === 7) {
      bg = 'rgba(255,0,0,0.1)';
    }
  }

  return (
    <div
      style={{
        ...styles.cellGrid,
        width: cellW,
        left: sidebirCell + index * cellW,
        background: bg
      }}>
      {grids && grids.length
        ? grids.map((it, i) => {
            const left = i * cellUnit;
            if (!left) return null;
            return (
              <div
                key={i}
                style={{ ...styles.cellGridDelimiter, left: i * cellUnit }}
              />
            );
          })
        : null}
    </div>
  );
};

const CellGrid = React.memo(CellGridComponent);

const EventBlockComponent = (props) => {
  const { children, style = {} } = props;
  return <div style={{ ...styles.eventsBlock, ...style }}>{children}</div>;
};

const EventBlock = React.memo(EventBlockComponent);

const ResourceCellComponent = (props) => {
  const { title, style = {} } = props;
  return (
    <div style={{ ...styles.td, ...styles.sidebirCell, ...style }}>{title}</div>
  );
};

const ResourceCell = React.memo(ResourceCellComponent);

const ResourceCellExecutorComponent = (props) => {
  const { resource = {}, style = {}, showTurnover } = props;
  let { title = '', sum_price = 0, total_count = 0 } = resource;
  const hasSumPrice = isNumeric(sum_price);
  return (
    <div style={{ ...styles.td, ...styles.sidebirCell, ...style }}>
      <div>{title}</div>
      {hasSumPrice && showTurnover && (
        <div
          style={{
            fontSize: 12,
            color: '#848383'
          }}>
          {`(${total_count} / ${toBills(sum_price, { separator: ' ' })} грн.)`}
        </div>
      )}
    </div>
  );
};

const ResourceCellExecutor = React.memo(ResourceCellExecutorComponent);

const RowComponent = ({ children, style = {} }) => {
  return <div style={{ ...styles.row, ...style }}>{children}</div>;
};

const Row = React.memo(RowComponent);

const CellComponent = ({ children, style = {} }) => {
  return <div style={{ ...styles.td, ...style }}>{children}</div>;
};

const Cell = React.memo(CellComponent);

const getCellUnit = (cellW, viewType) => {
  let unit = 0;

  let lessView = 'h';
  switch (viewType) {
    case 'week': {
      lessView = 'd';
      break;
    }
    default:
      lessView = 'h';
  }

  switch (lessView) {
    case 'h': {
      const { minute } = UNITS;
      if (minute) {
        const breakPoint = Math.round(60 / minute);
        unit = Math.floor(cellW / breakPoint);
      }
      break;
    }
    case 'd': {
      const { day } = UNITS;
      if (day) {
        const breakPoint = Math.round(24 / day);
        unit = Math.floor(cellW / breakPoint);
      }
      break;
    }
    default:
      unit = 0;
  }
  return unit;
};

const createEventItem = (
  event,
  currentDate,
  cellW,
  viewType,
  start,
  end,
  cellUnit
) => {
  let left = 0;
  let right = 0;
  let w = 0;

  const {
    start: eventStart,
    end: eventEnd
    // eventType
  } = event;

  if (eventEnd.isBefore(start)) return false;
  if (eventStart.isAfter(end)) return false;

  let lessView = 'h';
  switch (viewType) {
    case 'day': {
      lessView = 'h';
      break;
    }
    case 'week': {
      lessView = 'd';
      break;
    }
    default:
      lessView = 'h';
  }

  if (eventStart.isBefore(start)) {
    left = 0;
  } else {
    const diff = eventStart.diff(start, lessView);
    left = diff * cellW;
    if (viewType === 'day') {
      if (cellUnit) {
        const step = UNITS.minute;
        const minute = eventStart.get('minute');
        const unit = Math.floor(minute / step);
        // 1 - cell border
        left += unit * cellUnit + 1;
      }
    } else {
      const diff = eventStart
        .clone()
        .startOf('day')
        .diff(start.clone().startOf('day'), lessView);
      left = diff * cellW;
    }
  }

  if (viewType === 'day') {
    const diff = eventEnd.diff(start, lessView);
    right = diff * cellW;
    const minute = eventEnd.get('minute');
    if (cellUnit) {
      const step = UNITS.minute;
      const unit = Math.ceil(minute / step);
      right += unit * cellUnit;
    } else if (minute > 0) {
      right += cellW;
    }
  } else {
    const diff = eventEnd
      .clone()
      .startOf('day')
      .diff(start.clone().startOf('day'), lessView);
    right = diff * cellW;
    right += cellW;
  }

  w = right - left;
  return {
    left,
    right,
    w,
    event
  };
};

const prepareEvents = (
  events,
  resources,
  currentDate,
  cellW,
  viewType,
  start,
  end,
  cellUnit
) => {
  const res = {};
  const rels = {};

  const resourcesIds = {};
  resources.forEach((it, i) => {
    resourcesIds[it.id] = true;
  });
  if (!Array.isArray(events) || !events.length) return res;
  events.sort((a, b) => (a.start.isBefore(b.start) ? -1 : 1));
  events.forEach((ev) => {
    const { resourceId } = ev;
    if (!resourcesIds[resourceId]) return;
    if (!rels[resourceId]) {
      rels[resourceId] = [ev];
    } else {
      rels[resourceId].push(ev);
    }
  });
  Object.keys(rels).forEach((resourceId) => {
    const eventList = rels[resourceId];
    const dayHasEvent = {};
    eventList.forEach((ev) => {
      const item = createEventItem(
        ev,
        currentDate,
        cellW,
        viewType,
        start,
        end,
        cellUnit
      );
      if (!item) return;
      if (!res[resourceId]) {
        res[resourceId] = [[]];
      }
      const lines = res[resourceId];
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const last = line[line.length - 1];
        if (!line.length || item.left >= last.right) {
          const day = moment(item.event.start).date();
          const hasEvent = dayHasEvent[day];
          const isPushed = pushToLine(item, line, viewType, hasEvent);
          if (isPushed) {
            // const day = moment(item.event.start).date();
            dayHasEvent[day] = true;
          }
          return;
        } else {
          if (!lines[i + 1]) lines.push([]);
        }
      }
    });
  });
  return res;
};

const pushToLine = (item, line, viewType, hasEvent) => {
  const { event } = item;
  const { eventType, start: eventStart, end: eventEnd } = event;
  if (eventType === 'available') {
    const endH = eventEnd.clone().hour(DAY_END).startOf('hour');
    if (eventStart.isSameOrAfter(endH, 'h')) return;
    if (hasEvent) {
      const timeRange = event.end.diff(event.start, 'm');
      const offsetToEnd = eventStart
        .clone()
        .hour(DAY_END)
        .startOf('hour')
        .diff(eventEnd, 'm');
      if (timeRange < 30 && offsetToEnd > 30) {
        return;
      }
    } else {
      const startH = eventStart.clone().hour(DAY_START).startOf('hour');
      if (eventEnd.isBefore(startH, 'h')) return;
    }
  }
  line.push(item);
  return true;
};

const renderPopoverOrder = (resource, eventItem, data) => {
  const order = eventItem.order;
  const {
    street,
    locality,
    address_house,
    address_apartment,
    pay_type,
    comment,
    client,
    user
  } = order;
  const target = street || locality;
  const address = concatGeoFullName(target);
  const full_address = [address, address_house, address_apartment].join(', ');
  let date = null;
  const isSame = moment(order.date_from).isSame(order.date_to, 'day');
  if (isSame) {
    const d = moment(order.date_from).format(DATE_FORMAT);
    const t_from = moment(order.date_from).format(TIME_FORMAT);
    const t_to = moment(order.date_to).format(TIME_FORMAT);
    date = (
      <>
        {d} (<b>{`${t_from} - ${t_to}`}</b>)
      </>
    );
  } else {
    const d_from = moment(order.date_from).format(DATE_FORMAT);
    const t_from = moment(order.date_from).format(TIME_FORMAT);
    const d_to = moment(order.date_to).format(DATE_FORMAT);
    const t_to = moment(order.date_to).format(TIME_FORMAT);
    date = (
      <>
        {d_from} (<b>{t_from}</b>) &nbsp;
        {d_to} (<b>{t_to}</b>)
      </>
    );
  }
  const isNotRegionOrder =
    order && order.locality && order.locality.region_center === 'no';
  return (
    <div
      style={{ maxWidth: 400, fontSize: 12 }}
      onClick={(e) => e.stopPropagation()}>
      {data && data.orderLink ? (
        <div style={{ marginBottom: '8px' }}>
          <Link to={data.orderLink}>
            <b>Заявка: </b> {order.id}
          </Link>
        </div>
      ) : null}
      {!!isNotRegionOrder && (
        <div>
          <b style={{ color: '#0f0' }}>Область</b>
        </div>
      )}
      {full_address ? (
        <div>
          <b>Адрес:</b> {full_address}
        </div>
      ) : null}
      {client ? (
        <div>
          <b>Клиент:</b> {client.name}{' '}
          <Field value={client.phone} type="phone" />
        </div>
      ) : (
        <div>
          <b>Клиент:</b>
        </div>
      )}
      <div>
        <b>Дата, время:</b> {date}
      </div>
      {user ? (
        <div>
          <b>Оператор:</b> {[user.first_name, user.last_name].join(' ')}
        </div>
      ) : (
        <div>
          <b>Оператор:</b>
        </div>
      )}
      <div>
        <b>Цена:</b> {(order.price / 100).toFixed(2)}
      </div>
      <div>
        <b>За дорогу:</b> {(order.transport_price / 100).toFixed(2)}
      </div>
      <div>
        <b>Тип оплаты:</b> {(pay_type && pay_type.name) || ''}
      </div>
      <div style={{ maxHeight: 150 }}>
        <b>Примечание:</b> {comment}
      </div>
      <div>
        <b>Вредители:</b> {order?.reasons?.map((it) => it.name)?.join(', ')}
      </div>
      <div>
        <b>Услуги:</b> {order?.methods?.map((it) => it.name)?.join(', ')}
      </div>
      <div>
        <LabelField
          label="Материалы:"
          value={order?.materials_count ? 'Да' : 'Нет'}
        />
      </div>
      <div>
        <LabelField
          label="Документы:"
          value={order?.files_count ? 'Да' : 'Нет'}
        />
      </div>
      <div>
        <LabelField
          label="Активен:"
          value={order.is_active}
          type="boolean"
          format={(v) => v === 'yes'}
          iconSize="inherit"
        />
      </div>
      <div>
        <LabelField
          label="Выполнен:"
          value={order.is_finished}
          type="boolean"
          format={(v) => v === 'yes'}
          iconSize="inherit"
        />
      </div>
      <div>
        <LabelField
          label="Повтор:"
          value={order.is_repeat}
          type="boolean"
          format={(v) => v === 'yes'}
          iconSize="inherit"
        />
      </div>
      <div>
        <LabelField
          label="Оставлен чек:"
          value={order.is_feedback}
          type="boolean"
          format={(v) => v === 'yes'}
          iconSize="inherit"
        />
      </div>
    </div>
  );
};

const renderPopoverNotAvailable = (resource, eventItem) => {
  const notAvailable = eventItem.item;
  const { date_from, date_to, available_type } = notAvailable;

  let date = null;
  const isSame = moment(date_from).isSame(date_to, 'day');
  if (isSame) {
    const d = moment(date_from).format(DATE_FORMAT);
    const t_from = moment(date_from).format(TIME_FORMAT);
    const t_to = moment(date_to).format(TIME_FORMAT);
    date = (
      <>
        {d} (<b>{`${t_from} - ${t_to}`}</b>)
      </>
    );
  } else {
    const d_from = moment(date_from).format(DATE_FORMAT);
    const t_from = moment(date_from).format(TIME_FORMAT);
    const d_to = moment(date_to).format(DATE_FORMAT);
    const t_to = moment(date_to).format(TIME_FORMAT);
    date = (
      <>
        {d_from} (<b>{t_from}</b>) &nbsp;
        {d_to} (<b>{t_to}</b>)
      </>
    );
  }

  return (
    <div
      style={{ maxWidth: 400, fontSize: 12 }}
      onClick={(e) => e.stopPropagation()}>
      <div>
        <b>Дата, время:</b> {date}
      </div>
      <div>
        <b>Причина отсутствия:</b> {AVAILABLE_TYPES[available_type] || ''}
      </div>
    </div>
  );
};
