import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'qs';
import { bindActionCreators, compose } from 'redux';
import { submit } from 'redux-form';
import { theme } from 'styles';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';

import { makeUrlProjectId } from 'services/projectUtils';
import { userCanSeeTurnover } from 'services/utils';

import * as ordersActions from 'resources/Orders/actions';
import OrderAdd from 'resources/Orders/Add';
import { IconBtnRefresh } from 'components/buttons/IconBtn';
import Filter, {
  FilterBtn,
  FilterDateField,
  FilterProvider,
  FilterSearchElasticExecutorField,
  FilterSearchSelectField
} from 'components/Filter';
import Progress from 'components/Progress';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import SimpleModal from 'modals/SimpleModal';

import * as scheduleActions from './actions';
import Timeline from './Timeline';

const styles = {
  root: {
    padding: theme.spacing(1)
  },
  menuItem: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    whiteSpace: 'normal'
  },
  menuItemLabel: {
    fontWaight: 'bold'
  },
  menuItemNotice: {
    fontSize: '0.7em'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  filter: {
    flex: 1,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      order: 2
    }
  },
  switchBtn: {
    flexShrink: 0,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center'
    }
  }
};

function createQs(params) {
  if (!params) return {};
  const next = {
    ...params
  };
  if (next.currentDate)
    next.currentDate = next.currentDate.format('YYYY-MM-DD');
  if (Object.keys(next).length) return '?' + queryString.stringify(next);
  return '';
}

function parseQuery(query) {
  if (!query) return {};
  const params = queryString.parse(query, { ignoreQueryPrefix: true });
  if (params.currentDate) params.currentDate = moment(params.currentDate);
  return params;
}

class ScheduleScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalAdd: null,
      modalInnerProps: {}
    };
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = parseQuery(search);
      this.sendRequest(q);
    }
  }

  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = parseQuery(search);
    this.getData(q);
  }

  componentWillUnmount() {
    const { scheduleActions = {} } = this.props;
    scheduleActions.resetState();
  }

  getData = (params = {}) => {
    const { history = {} } = this.props;
    const qs = createQs(params);

    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      this.sendRequest(params);
    }
  };

  sendRequest = (params = {}) => {
    const { scheduleActions = {}, params: oldParams = {} } = this.props;
    const { viewType = 'day' } = oldParams;
    scheduleActions.getScheduleData({
      currentDate: params.currentDate ? params.currentDate : moment(),
      viewType,
      ...params
    });
  };

  onFilter = (values) => {
    const { params = {} } = this.props;
    const { viewType = 'day' } = params;
    const currentDate = moment(values.date);

    const filter = {};
    if (values.executor_id) filter.executor_id = values.executor_id;
    if (values.user_id) filter.user_id = values.user_id;
    if (values.place_id) filter.place_id = values.place_id;

    this.getData({
      viewType,
      currentDate,
      filter
    });
  };

  refresh = () => {
    const { params } = this.props;
    this.sendRequest(params);
  };

  onViewChange = (view) => {
    const { params } = this.props;
    this.getData({
      ...params,
      viewType: view
    });
  };

  eventItemClick = (resource, event) => {
    const executor = resource;

    if (event.eventType === 'available') {
      this.handleShowAdd({
        executor,
        date_from: event.start ? event.start.toISOString() : null,
        date_to: event.end ? event.end.toISOString() : null
      });
      return;
    }

    if (event.eventType === 'order') {
      const { order } = event;
      const { history, location } = this.props;
      let from = location.pathname;
      if (location.search) from += location.search;
      history.push({
        pathname: makeUrlProjectId(`/orders/show/${order.id}`),
        state: { from }
      });
    }

    if (event.eventType === 'notAvailable') {
      const { item } = event;
      const { history, location } = this.props;
      let from = location.pathname;
      if (location.search) from += location.search;
      history.push({
        pathname: makeUrlProjectId(`/executors_available/edit/${item.id}`),
        state: { from }
      });
    }
  };

  // handleCreateOrder = async (data) => {
  //   const { dispatch } = this.props;
  //   try {
  //     await dispatch(ordersActions.createOne(data));
  //     this.setState({orderModalProps:null});
  //     this.refresh();
  //   } catch (err) {}
  // }

  handleCreate = async (data) => {
    const { ordersActions } = this.props;
    try {
      await ordersActions.createOne(data);
      this.handleHideAdd();
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  handleShowAdd = (record) => {
    const { dispatch } = this.props;
    const formName = 'add_order';
    this.setState({
      modalAdd: {
        confirmText: 'Создать',
        title: 'Создание заявки',
        handleClose: this.handleHideAdd,
        handleSuccess: () => dispatch(submit(formName))
      },
      modalInnerProps: {
        formName,
        record,
        handleCreate: this.handleCreate
      }
    });
  };

  handleHideAdd = () => {
    this.setState({
      modalAdd: null,
      modalInnerProps: null
    });
  };

  render() {
    const {
      loading,
      resources = [],
      events = [],
      classes = {},
      params = {},
      admin
    } = this.props;

    const { currentDate = moment(), viewType = 'day', filter = {} } = params;

    const { modalAdd, modalInnerProps = {} } = this.state;

    const showTurnover = userCanSeeTurnover(admin);

    return (
      <Screen
        title="Расписание"
        classes={{
          paper: classes.root
        }}>
        {loading && <Progress variant="over" />}
        <FilterProvider>
          <div className={classes.header}>
            <Filter
              className={classes.filter}
              initialValues={{
                date: currentDate,
                ...filter
              }}
              onFilter={this.onFilter}>
              <FilterDateField
                source="date"
                alwaysOn
                label="Дата"
                submitOnChange={true}
                dateType="date"
                clearable={false}
              />
              <FilterSearchSelectField
                alwaysOn
                source="place_id"
                label="Дислокация"
                requestPath="/executor_places"
                responseFormat={(v) => v.results}
                searchBy="name"
                getValue={(o) => o.id}
              />
              <FilterSearchSelectField
                source="user_id"
                alwaysOn
                label="Оператор"
                requestPath="/users"
                responseFormat={(v) => v.results}
                getOptionLabel={(it) => [it.first_name, it.last_name].join(' ')}
                searchBy={['first_name', 'last_name']}
                getValue={(o) => o.id}
              />
              <FilterSearchElasticExecutorField
                source="executor_id"
                is_active="yes"
                label="Дезинфектор"
              />
            </Filter>
            <div className={classes.switchBtn}>
              <FilterBtn />
              <IconBtnRefresh onClick={() => this.refresh()} />
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group">
                <Button
                  variant={viewType === 'day' ? 'contained' : 'outlined'}
                  onClick={() => {
                    this.onViewChange('day');
                  }}>
                  День
                </Button>
                <Button
                  variant={viewType === 'week' ? 'contained' : 'outlined'}
                  onClick={() => {
                    this.onViewChange('week');
                  }}>
                  Неделя
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </FilterProvider>
        <Timeline
          resources={resources}
          events={events}
          viewType={viewType}
          currentDate={currentDate}
          eventItemClick={this.eventItemClick}
          showTurnover={showTurnover}
        />
        <Rbac operation="orders:create">
          <SimpleModal
            isOpen={!!modalAdd}
            cancelText="Отмена"
            maxWidth="xl"
            {...modalAdd}>
            <OrderAdd footerBar={false} title={null} {...modalInnerProps} />
          </SimpleModal>
        </Rbac>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin,
    loading: state.schedule.loading,
    params: state.schedule.params,
    resources: state.schedule.resources,
    events: state.schedule.events
    // locations: state.schedule.locations
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  scheduleActions: bindActionCreators(scheduleActions, dispatch),
  ordersActions: bindActionCreators(ordersActions, dispatch)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScheduleScreen);
